/* Sizes */

$screen__xs: 375px;
$phone_max: 767px;
$tablet_min: 768px;
$tablet_max: 1200px;
$desktop_min: 1201px;

// Colors

$color_bg_grey: #f3f2ef;
$color_black: #000000;
$color_brown: #735e52;
$color_dark_gold: #9d897e;
$color_dark_grey: #c8c8c8;
$color_dark_grey_2: #212121;
$color_gold: #e4d1c0;
$color_green: #89bc6a;
$color_green_2: #45ab55;
$color_grey: #38373d;
$color_grey_2: #aaaaaa;
$color_grey_3: #e6e4de;
$color_grey_4: #545454;
$color_grey_light: #dadada;
$color_header_border: #e4e4e4;
$color_overlay: #646369;
$color_popup_overlay: #212121b3;
$color_red: #ff0000;
$color_red_light: #f6dad9;
$color_red_text: #b91d1d;
$color_red_bg: #a52929;
$color_red_cart_bg: #C43A2E;
$color_tide: #b8b6ae;
$color_timberwolf: #d7d5cf;
$color_white: #ffffff;
$color_white_smoke: #f8f8f8;
$color_yellow: #ffe064;
$color_yellow_light: #ffefae;
$color_yellow_text: #756421;

// Shadows

$shadow_dropdown: 0px 15px 50px 0px rgba(0, 0, 0, 0.15);

// Fonts

$cormorant: "Cormorant", sans-serif;
$roboto: "Roboto", sans-serif;
$inter: "Inter", sans-serif;
