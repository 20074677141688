@import "../uikit/vars/vars";
@import "../uikit/mixins";

// Weekly selections

.weekly-selections {
  padding: 0;
  text-align: start;

  &__blocks-wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0 0 0;
    overflow: hidden;
    gap: 20px;

    @media (min-width: $desktop_min) {
      flex-direction: row;
      justify-content: center;
      margin-top: 40px;
    }

    .info-block-half {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 670px;

      @media (max-width: $tablet_max) {
        width: 100%;
      }
    }
  }

  .info-block-half__txt {
    margin-top: 13px;

    p {
      @include custom-font-inter(300, 16px, 23px);
    }
  }

  .info-block-half__desc {
    padding-right: 0;
    text-align: start;
  }

  @media (min-width: $desktop-min) {
    h3 {
      @include h2;
    }
  }

  @media (max-width: $tablet_max) {
    &__blocks-wrap {
      .info-block-half {
        &__content {
          padding: 0 60px 60px;
        }
        &__txt {
          margin-top: 25px;
        }
      }
    }
  }

  @media (max-width: $phone_max) {
    &__blocks-wrap {
      .info-block-half {
        &__content {
          flex-direction: column;
          padding: 0 30px 30px 30px;
        }
        &__txt {
          margin-top: 35px;
        }
      }
    }
  }
}

// Info block half
// Info block fw
.info-block-half,
.info-block-fw {
  position: relative;
  display: inline-flex;
  min-height: 600px;

  @media (max-width: $tablet_max) {
    min-height: 600px;
  }

  @media (max-width: $phone_max) {
    min-height: 410px;
    align-items: center;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba(73, 73, 73, 0.32) 0%,
      rgba(14, 14, 14, 0.54) 100%
    );
  }
  &__image {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    picture {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content {
    position: relative;
    padding: 60px 60px 60px 60px;
    color: $color_white;
    display: flex;
    width: 100%;
    align-items: flex-end;
    z-index: 1;
    @media all and (max-width: $phone_max) {
      padding: 30px 30px 30px 30px;
    }
  }
  &__desc {
    max-width: 478px;
    // padding-right: 20px;
    margin-top: auto;
  }
  &__txt {
    @include body__text;
    margin-top: 25px;

    @media (max-width: $phone-max) {
      margin-top: 35px;
    }
  }
  &__link {
    margin-left: auto;
    z-index: 2;
  }
}

// Info block full width
.info-block-fw {
  display: flex;
  &__desc {
    max-width: 785px;
    padding-right: 20px;
    @media all and (max-width: $phone_max) {
      padding-right: 0;
      text-align: center;
    }
  }
  &__content {
    @media all and (max-width: $tablet_max) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media all and (max-width: $phone_max) {
      align-items: center;
    }
  }
  .btn {
    margin-left: auto;
    z-index: 2;
    flex: 0 0 auto;
    padding: 0;
    width: initial;
    @media all and (max-width: $tablet_max) {
      margin-left: 0;
      margin-top: 30px;
    }
  }
}

// Info block half
.info-block-half {
  @media all and (max-width: $tablet_max) {
    width: 100%;
  }

  &__content {
    @media all and (max-width: 1439px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media all and (max-width: $phone_max) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__link {
    @media (max-width: 1439px) {
      margin-left: 0;
      margin-top: 10px;
    }

    @media (max-width: $phone_max) {
      margin-top: 20px;
    }
  }
}

// info block type-1

.info-block-type-1 {
  max-width: 325px;
  width: 100%;
  display: inline-flex;
  flex-direction: column;

  &__image {
    display: flex;
    img {
      max-width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  &__content {
    margin-top: 20px;
  }
}
