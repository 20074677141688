@import "../uikit/vars/vars";
@import "../uikit/mixins";

.subscribe-block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 40px 158px 40px 224px;
  background: $color_dark_grey_2;
  color: $color_white;

  transition: height 0.3s linear;
  min-height: 194px;

  @media (max-width: $tablet_max) {
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    gap: 41px;
  }
  &__form {
    display: flex;
    align-items: center;
    gap: 8px;
    @media (max-width: $phone_max) {
      flex-direction: column;
      gap: 8px;
    }
    &-input {
      position: relative;
      input {
        background-color: transparent;
        border: 1px solid $color_grey_light;
        border-radius: 0;
        padding: 22px 18px 22px 30px;
        width: 325px;
        max-height: 60px;
        @include custom-font-inter(700, 14px, 18px);
        color: $color_white;
        @media (max-width: $phone_max) {
          padding: 22px 20px;
          width: 280px;
          max-height: 50px;
        }
      }
      label {
        position: absolute;
        top: 0;
        left: 20px;
        transform: translateY(-50%);
        background-color: $color_dark_grey_2;
        color: $color_grey_2;
        padding: 0 10px;
        @include custom-font-inter(400, 12px, normal);
        @media all and (max-width: $phone_max) {
          left: 10px;
        }
      }
    }
  }
  &__txt {
    // flex: 1 1 50%;
    // justify-content: center;
    text-align: center;
    @media all and (max-width: $tablet_max) {
      flex: 1 1 100%;
      width: 100%;
    }
    span {
      @include custom-font-inter(400, 26px, 33px);
      margin-bottom: 10px;
      @media (max-width: $phone_max) {
        font-size: 22px;
        line-height: normal;
      }
    }
    p {
      @include custom-font-inter(300, 16px, 23px);
    }
  }

  // Styles for another version of subscribe-block. Don't remove
  // &__ul {
  //   display: flex;
  //   justify-content: center;
  //   @media all and (max-width: $phone_max) {
  //     flex-direction: column;
  //   }
  // }
  // &__li {
  //   padding: 20px 60px;
  //   text-transform: uppercase;
  //   border-left: 1px;
  //   position: relative;
  //   @media all and (max-width: $phone_max) {
  //     padding: 15px 15px;
  //   }
  //   &:before {
  //     content: "";
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 1px;
  //     height: 100%;
  //     background: rgba(255, 255, 255, 0.2);
  //     @media all and (max-width: $phone_max) {
  //       width: 45%;
  //       left: 50%;
  //       transform: translateX(-50%);
  //       height: 1px;
  //     }
  //   }
  //   &:last-child {
  //     &:after {
  //       content: "";
  //       position: absolute;
  //       top: 0;
  //       right: 0;
  //       width: 1px;
  //       height: 100%;
  //       background: rgba(255, 255, 255, 0.2);
  //       @media all and (max-width: $phone_max) {
  //         width: 45%;
  //         height: 1px;
  //         top: 100%;
  //         left: 50%;
  //         transform: translateX(-50%);
  //       }
  //     }
  //   }
  // }
  // &__form {
  //   display: none;
  //   position: relative;
  // }
  // &__close-btn {
  //   display: block;
  //   height: 16px;
  //   width: 16px;
  //   position: absolute;
  //   right: -40px;
  //   top: -30px;
  //   cursor: pointer;
  //   background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.707153' y='0.510742' width='10.8266' height='1' transform='rotate(45 0.707153 0.510742)' fill='%239D897E'/%3E%3Crect width='10.8266' height='1' transform='matrix(0.707107 -0.707107 -0.707107 -0.707107 0.707153 16.5107)' fill='%239D897E'/%3E%3Crect x='15.308' y='16.5107' width='10.8266' height='1' transform='rotate(-135 15.308 16.5107)' fill='%239D897E'/%3E%3Crect width='10.8266' height='1' transform='matrix(-0.707107 0.707107 0.707107 0.707107 15.308 0.510742)' fill='%239D897E'/%3E%3C/svg%3E%0A");
  //   transition: opacity 0.3s linear;
  //   @media all and (max-width: $phone_max) {
  //     right: -10px;
  //   }
  //   &:hover {
  //     opacity: 0.6;
  //   }
  // }
  // form {
  //   display: flex;
  //   width: 100%;
  //   align-items: flex-start;
  //   @media all and (max-width: $tablet_max) {
  //     flex-direction: column;
  //   }
  // }
  // fieldset {
  //   border: 1px solid $color_white;
  //   text-align: left;
  //   height: 65px;
  // }
  // legend {
  //   padding-left: 10px;
  //   padding-right: 10px;
  //   margin-left: 15px;
  //   color: $color_dark_grey;
  //   font-weight: 400;
  //   font-size: 10px;
  // }
  // &__input-wrap {
  //   width: 100%;
  //   max-width: 325px;
  //   margin-right: 20px;
  //   input {
  //     width: 100%;
  //     border: 0;
  //     background: $color_grey;
  //     margin: 15px 0;
  //     padding: 0 30px 0 30px;
  //     color: $color_white;
  //     font-family: $roboto;
  //     text-transform: uppercase;
  //     font-style: normal;
  //     font-weight: 700;
  //     font-size: 12px;
  //     line-height: 130%;
  //     &:focus {
  //       outline: 1px solid transparent;
  //     }
  //   }
  //   @media all and (max-width: $tablet_max) {
  //     max-width: 100%;
  //     margin-right: 0;
  //   }
  // }
  // .btn {
  //   font-weight: 700;
  //   margin-top: 4px;
  // }
  // .checkbox-container {
  //   margin-top: 15px;
  //   * {
  //     color: $color_dark_grey;
  //   }
  //   @media all and (max-width: $tablet_max) {
  //     margin-bottom: 15px;
  //   }
  // }
}
