@import "../uikit/vars/vars";
@import "../uikit/mixins";
@import "../templates/tooltip.scss";
@import "../templates/swiper.scss";
@import "../templates/info-block.scss";
@import "../templates/blog-block.scss";
@import "../templates/cookie-policy.scss";
@import "../templates/subscribe-block.scss";

:root {
  --tooltip-opacity: 1;
}

.tooltipster-show {
  opacity: var(--tooltip-opacity) !important;
}

// Swiper banner

.aroma-swiper-container {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;

  @media (max-width: $tablet_max) {
    padding: 0 21px;
  }

  @media (max-width: $phone-max) {
    padding: 0;
  }
}

.look-at-me {
  aspect-ratio: 2.34 / 1;
  overflow: hidden;
  @media (max-width: $phone_max) {
    aspect-ratio: 0.714 / 1;
  }
  &-item {
    img {
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }

  .slider-pagination {
    display: flex;
    position: absolute;
    left: 57px;
    bottom: 46px;
    width: calc(100% - 202px);
    height: 1px;
    background-color: $color_grey_2;
    z-index: 1;

    .swiper-pagination-bullet {
      flex: 1 1 auto;
      height: 1px;
      background: $color_white;
      margin: 0;
      border-radius: 0;
    }

    @media (max-width: $tablet_max) {
      bottom: 43px;
      left: 60px;
      margin-bottom: 0;
    }

    @media (max-width: $phone_max) {
      bottom: 48px;
      left: 20px;
      width: calc(100% - 118px);
    }
  }
  .swiper-pagination-lock {
    display: none;
  }

  .arrow-prev-white,
  .arrow-next-white {
    position: absolute;
    z-index: 1;
    bottom: 33px;

    @media (max-width: $tablet-max) {
      bottom: 30px;
    }

    @media (max-width: $phone-max) {
      bottom: 36px;
    }
  }

  .arrow-next-white {
    right: 64px;

    @media (max-width: $phone_max) {
      right: 20px;
    }
  }

  .arrow-prev-white {
    right: 97px;

    @media (max-width: $phone_max) {
      right: 54px;
    }
  }

  .swiper-button-disabled {
    opacity: 0.5 !important;
  }
}

// Items of week block

.main-items-holder {
  margin: 80px 0;
  padding: 0;
  text-align: center;

  &__title {
    margin-bottom: 40px;
  }

  &__products {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 20px;
    max-width: 1360px;
    margin: 0 0 40px;

    &:hover {
      overflow-x: visible;
    }
  }

  @media (max-width: $tablet_max) {
    padding: 0 34px;
    margin: 60px 0 48px;
    &__title {
      margin-bottom: 30px;
    }
    &__products {
      flex-wrap: wrap;
      gap: 20px 10px;
      margin: 0 auto 30px;
    }
  }

  @media (max-width: $phone_max) {
    padding: 0;
    margin: 60px 0;

    &__products {
      justify-content: center;
      overflow-x: hidden;
      max-width: 320px;
      gap: 20px 20px;
    }
  }
}

// Editor Choise
.editor-choise {
  position: relative;
  display: flex;
  padding-bottom: 47px;
  margin-bottom: 48px;
  color: $color_grey;

  &__swiper {
    width: 100%;
    // &:hover {
    //   overflow-y: visible;
    // }
  }

  &__main {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 49px 77px 49px 0;

    width: 268px;

    &::before {
      display: none;
    }

    h2 {
      color: black;
    }

    .btn {
      margin: 0 auto;
    }
  }

  &__products {
    display: flex;

    @media (min-width: 529px) and (max-width: 767px) {
      justify-content: center;
    }
  }

  &__img {
    border-radius: 50%;
    width: 90px;
    height: 90px;
    margin: 30px auto;
  }

  &__name {
    text-align: center;
    @include h6;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    max-width: 192px;
    margin: 0 auto;
    margin-bottom: 2px;
  }

  &__proff {
    @include custom-font-inter(300, 14px, 17px);
    margin-bottom: 40px;
  }

  &__swiper {
    position: static !important;
  }

  &__nav {
    position: absolute;
    left: 0;
    bottom: 0;
    @include flex-center;
    gap: 27px;
    width: 100%;

    &-arrows {
      display: flex;
      justify-content: space-between;
      height: 27px;
      width: 47px;
    }

    .pagination {
      display: flex;
      width: calc(100% - 58px);
      height: 1px !important;

      background-color: $color_dark_grey;

      .swiper-pagination-bullet {
        height: 1px;
        flex: 1 1 auto;
        background: $color_dark_grey_2;
        margin: 0 !important;
      }
    }

    .swiper-pagination-lock {
      display: none;
    }

    .arrow-left,
    .arrow-right {
      width: 14px;
      height: 100%;

      opacity: 1;
      cursor: pointer;
      background: url("../../images/icons/arrow_right.svg") no-repeat center;
      background-size: cover;
    }

    .arrow-left {
      transform: rotate(180deg);
    }
  }

  .btn-bottom {
    display: none;
  }

  @media (max-width: $tablet-max) {
    padding-left: 31px;
    &__main {
      margin: 49px 20px 49px 0;
    }
    &__nav {
      left: 31px;
      width: 667px;
    }
  }

  @media (max-width: $phone_max) {
    position: static;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 60px;

    &__main {
      width: 100%;
      margin: 0;

      .btn {
        display: none;
      }
    }

    &__img {
      margin: 30px auto 5px;
      width: 32px;
      height: 32px;
    }

    &__name {
      font-family: $roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.02em;
      margin-bottom: 0;
      max-width: fit-content;
    }

    &__proff {
      font-family: $cormorant;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 143%;
      margin-bottom: 30px;
    }

    &__products {
      width: 100%;
      margin-bottom: 20px;
    }

    &__nav {
      position: static;
      width: fit-content;
      margin: 0 auto;
      .pagination {
        display: none;
      }

      &-arrows {
        height: 20px;
        width: 40px;
      }

      .arrow-left,
      .arrow-right {
        width: 10px;
      }
    }

    .btn-bottom {
      display: flex;
      margin-top: 30px;
    }
  }
}

// Exclusive of the week

.exclusive-bg {
  position: relative;
  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 63% 37%;
    overflow: hidden;
    @media (max-width: $tablet_max) {
      grid-template-columns: 1fr;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    &.secondary {
      @media (max-width: $tablet_max) {
       display: none;
      }
    }
  }
}

.exclusive-of-the-week {
  position: relative;
  @include flex-center;
  color: $color_white;
  padding: 86px 0;
  margin: 0 -10px;

  @media (max-width: $tablet_max) {
    flex-direction: column-reverse;
    padding: 80px 0 99px;
  }

  @media (max-width: $phone_max) {
    padding: 40px 0;

    &__image {
      padding: 0 52px;

      img {
        max-width: initial;
        max-height: 294px;
        margin-bottom: 20px;
      }
    }
  }

  &__desc {
    text-align: center;
    padding-left: 58px;

    @media (max-width: $tablet_max) {
      padding-left: 0;

      > * {
        max-width: 607px;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-transform: uppercase;

      margin-bottom: 30px;

      @media (max-width: $phone_max) {
        margin-bottom: 10px;
      }
    }

    h1 {
      color: $color_white;
      margin-bottom: 30px;

      @media (min-width: $desktop_min) {
        padding-bottom: 7px;
      }

      @media (min-width: $tablet_min) {
        line-height: 66px;
      }

      @media (max-width: $phone_max) {
        margin-bottom: 20px;
      }
    }

    > * {
      max-width: 497px;
    }
  }

  &__desc,
  &__image {
    flex: 1 1 calc(50% - 20px);
    margin: 0 10px;
  }
  &__image {
    img {
      width: 100%;
      height: 100%;
      max-width: 440px;
      max-height: 554px;
      object-fit: cover;
    }

    @media (max-width: $tablet_max) {
      width: 100%;
      max-height: 613px;
      padding: 0 154px;

      img {
        max-width: initial;
        margin-bottom: 58px;
      }
    }

    @media (max-width: $phone_max) {
      max-height: 314px;
    }
  }

  &__txt {
    @include custom-font-inter(300, 16px, 23px);
  }

  &__btns {
    display: flex;
    flex-direction: column;
    margin-top: 27px;
    align-items: center;

    a:first-child {
      margin-bottom: 33px;
    }

    @media (min-width: $tablet_min) {
      margin-top: 60px;

      a:first-child {
        margin-bottom: 35px;
      }
    }
  }
}

// Brand new

.brand-new {
  margin-top: 60px;
  text-align: center;

  @media (min-width: $desktop_min) {
    text-align: start;
    padding: 0;

    &__columns {
      margin-top: 40px;
    }

    &__big-img {
      flex: 1 1 calc(50% - 20px);
    }

    &__products {
      margin-bottom: 20px;
    }

    .link {
      font-family: $roboto;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.02em;

      width: fit-content;

      &::before {
        bottom: 0;
      }
    }
  }

  @media (min-width: $tablet_min) {
    padding: 0 31px;

    &__big-img {
      flex: none;
      height: 596px;
    }

    .link {
      display: block;
      width: fit-content;
    }
  }

  &__columns {
    display: flex;
    flex-wrap: wrap;
    margin: 30px -10px 0 -10px;

    @media all and (max-width: $tablet_max) {
      flex-direction: column;
    }
  }

  &__big-img {
    position: relative;
    overflow: hidden;

    @media (max-width: $tablet_max) {
      margin-bottom: 20px;
    }

    @media (max-width: $phone_max) {
      height: 120px;
      flex: none;
    }
  }

  &__big-img,
  &__right {
    margin: 0 10px;
    flex: 1 1 calc(50% - 20px);
  }

  &__bg-img {
    width: 100%;
    height: auto;
    object-fit: cover;

    @media (max-width: $phone_max) {
      height: 120px;
    }
  }

  &__brand-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: $phone_max) {
      width: 111px;
      height: 38px;
    }
  }

  &__products {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;

    @media (max-width: $phone-max) {
      gap: 10px;
    }
  }
}

// blog

.blog-block {
  margin: 60px 0;
  @media (max-width: $tablet-max) {
    padding: 0 31px;
    &__nav {
      padding-right: 21px;
    }
  }
  @media (max-width: $phone-max) {
    width: 320px;
    margin-left: auto;
    margin-right: auto;
    &__nav {
      margin-top: 10px;
    }
  }
}

// Weekly selections
.weekly-selections {
  margin: 60px 0 20px;
  @media (max-width: $tablet-max) {
    padding: 0 31px;
    text-align: center;
    &__blocks-wrap {
      .info-block-half {
        &__content {
          padding: 0 58px 60px;
        }
        &__txt {
          margin-top: 13px;
        }
      }
    }
  }
  @media (max-width: $phone-max) {
    margin: 60px 0;
    padding: 0;

    h2 {
      margin-bottom: 30px;
    }

    .info-block-half {
      &__txt {
        margin-top: 20px;
      }
    }
  }
}

// Look at me full width banner
.aroma-container-block {
  max-width: 1440px;
  padding: 0 40px;
  margin: 0 auto;

  @media (max-width: $tablet_max) {
    padding: 0 51px;
    max-width: 768px;
  }

  @media (max-width: $phone_max) {
    padding: 0;
  }
}

.look-at-me-2 {
  .info-block-fw__content {
    @media (min-width: $desktop_min) {
      padding: 276px 52px 80px 58px;
    }
  }

  .info-block-fw__desc {
    margin-top: 0;
    padding-right: 3px;

    h2 {
      @include h1;
      color: $color_white;
    }
  }

  .info-block-fw__txt {
    a {
      p {
        @include custom-font-inter(300, 16px, 23px);
        margin-top: 17px;
        color: $color_white;
      }
    }
  }

  .btn_goto-svg-arrow {
    span {
      margin-left: 20px;
    }
  }

  @media (max-width: $tablet_max) {
    .info-block-fw__desc {
      margin-top: 50px;
      padding-right: 3px;
    }

    .btn_goto-svg-arrow {
      margin-top: 60px;
    }
  }

  @media (max-width: $phone_max) {
    .info-block-fw__content {
      padding: 0 20px 40px;
    }

    .info-block-fw__desc {
      margin-top: 132px;
    }

    .info-block-fw__txt {
      margin-top: 6px;
    }

    .btn_goto-svg-arrow {
      margin-top: 30px;
    }
  }
}

.subscribe-block {
  margin-top: 80px;
  margin-bottom: 80px;
  @media (max-width: $tablet-max) {
    width: auto;
    margin: 0 31px;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  @media (max-width: $phone-max) {
    margin-left: -20px;
    margin-right: -20px;
  }
}
