// swiper-arrows

.arrow-prev,
.arrow-next,
.arrow-prev-white,
.arrow-next-white {
  width: 14px;
  height: 27px;
  opacity: 1;
  padding: 0;
  cursor: pointer;
}

.arrow-next {
  background: url("../../images/icons/nav_arr.svg");
}

.arrow-prev {
  background: url("../../images/icons/nav_arr.svg");
  transform: rotate(180deg);
}

.arrow-next-white {
  background: url("../../images/icons/nav_arr_white.svg");
}

.arrow-prev-white {
  background: url("../../images/icons/nav_arr_white.svg");
  transform: rotate(180deg);
}

.swiper-button-disabled {
  opacity: 0.2 !important;
}
