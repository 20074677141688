@import "../uikit/vars/vars";
@import "../uikit/mixins";

// tooltip ico
.tooltip-ico {
  background: $color_red;
  display: inline-flex;
  border-radius: 50%;
  color: $color_white;
  position: relative;
  width: 11px;
  height: 11px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: "?";
    position: static;
    font-size: 9px;
    display: block;
    width: initial;
    height: initial;
    padding-left: 1px;
    // background: url("../../images/icons/question_mark_white.svg") center no-repeat;
    // transform: translate(-50%, -50%);
  }
  &_info {
    color: $color_black;
    background: $color_dark_grey;
    width: 11px;
    height: 11px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &:before {
      color: $color_black;
      content: "!";
      position: static;
      font-size: 9px;
      display: block;
      width: initial;
      height: initial;
      padding-left: 1px;
      transform: rotate(180deg);
      background: none;
    }
  }
}

// tooltipe styles
.tooltipster-sidetip .tooltipster-box {
  background: $color_white;
  border: 2px solid $color_bg_grey;
}
.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border {
  border-top-color: $color_bg_grey;
}
.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: $color_bg_grey;
}
.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
  border-top-color: $color_bg_grey;
}
.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
  border-bottom-color: $color_bg_grey;
}
.tooltip_templates {
  display: none;
}
.tooltipster-sidetip .tooltipster-box {
  border-radius: 20px;
}
.tooltipster-sidetip .tooltipster-content {
  color: $color_tide;
  font-weight: 400;
  text-align: center;
  .txt-grey {
    color: $color_grey;
  }
}
