@import "./vars/vars";

// Flex

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// Text

@mixin h1 {
  font-family: $inter;
  font-style: normal;
  font-weight: 300;
  font-size: 64px;
  line-height: 60px;
  color: $color_dark_grey_2;

  @media (max-width: $phone_max) {
    font-size: 40px;
    line-height: 45px;
  }
}

@mixin h2 {
  font-family: $inter;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 36px;

  @media (max-width: $phone_max) {
    font-size: 26px;
    line-height: 30px;
  }
}

@mixin h3 {
  font-family: $inter;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;

  @media (max-width: $phone_max) {
    font-size: 22px;
    line-height: 27px;
  }
}

@mixin h4 {
  font-family: $inter;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
}

@mixin h5 {
  font-family: $inter;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

@mixin h6 {
  font-family: $inter;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

@mixin body__text {
  font-family: $inter;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
}

@mixin txt__small-body {
  font-family: $inter;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
}

@mixin txt__small-addition {
  font-family: $inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

@mixin txt__cormorant {
  font-family: $cormorant;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
}

@mixin txt__roboto {
  font-family: $roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
}

@mixin txt__aroma-icon {
  font-family: "aroma-icons";
  font-weight: 400;
}

@mixin custom-font-inter($weight, $size, $line-height) {
  font-family: "Inter", sans-serif;
  font-weight: $weight;
  font-size: $size;
  line-height: $line-height;
}

// Buttons

@mixin hover-border {
  &::before,
  &::after {
    content: "";
    width: 0;
    height: 1px;
    position: absolute;
    transition: all 0.2s linear;
    background: $color_brown;
  }
  span::before,
  span::after {
    content: "";
    width: 1px;
    height: 0;
    position: absolute;
    transition: all 0.2s linear;
    background: $color_brown;
  }
  &:hover::before,
  &:hover::after {
    width: 100%;
  }
  &:hover span::before,
  &:hover span::after {
    height: 100%;
  }
  &::after {
    right: 0;
    bottom: 0;
    transition-delay: 0.6s;
  }
  & span::after {
    transition-delay: 0.4s;
    left: 0;
    bottom: 0;
  }
  &::before {
    left: 0;
    top: 0;
    transition-delay: 0.2s;
  }
  & span::before {
    transition-delay: 0s;
    right: 0;
    top: 0;
  }
  &:hover::after {
    transition-delay: 0s;
  }
  &:hover span::after {
    transition-delay: 0.2s;
  }
  &:hover::before {
    transition-delay: 0.4s;
  }
  &:hover span::before {
    transition-delay: 0.6s;
  }
}

@mixin hover-border-reverse {
  &::before,
  &::after {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    background: $color_dark_grey_2;
  }

  span::before,
  span::after {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    background: $color_dark_grey_2;
  }
  &::before {
    left: 0;
    top: 0;
    transition: 0.2s width ease-in-out;
    transition-delay: 0.4s;
  }
  &::after {
    right: 0;
    bottom: 0;
    transition: 0.2s width ease-in-out;
  }
  & span::before {
    right: 0;
    top: 0;
    transition: 0.2s height ease-in-out;
    transition-delay: 0.6s;
  }
  & span::after {
    left: 0;
    bottom: 0;
    transition: 0.2s height ease-in-out;
    transition-delay: 0.2s;
  }
  &:hover span:before {
    height: 0;
    transition: 0.2s height ease-in-out;
  }
  &:hover:before {
    width: 0;
    transition: 0.2s width ease-in-out;
    transition-delay: 0.2s;
  }
  &:hover span:after {
    height: 0;
    transition: 0.2s height ease-in-out;
    transition-delay: 0.4s;
  }
  &:hover:after {
    width: 0;
    transition: 0.2s width ease-in-out;
    transition-delay: 0.6s;
  }
}

@mixin btn-text {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  text-transform: uppercase;
}

@mixin btn-goto(
  $bg,
  $text-color,
  $border-color,
  $bg-hover,
  $text-color-hover,
  $border-color-hover
) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 60px;
  background-color: $bg;
  border: 1px solid $border-color;
  transition: background-color 0.3s ease;
  cursor: pointer;
  span {
    @include custom-font-inter(700, 14px, 18px);
    color: $text-color;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    transition: color 0.3s ease;
  }
  &:active {
    background-color: $bg-hover;
    border-color: $border-color-hover;
    span {
      color: $text-color-hover;
    }
  }
  @media (min-width: $desktop_min) {
    &:hover {
      background-color: $bg-hover;
      border-color: $border-color-hover;
      span {
        color: $text-color-hover;
      }
    }
  }

  &:disabled {
    cursor: default;
    &:hover {
      background-color: $bg;
      span {
        color: $text-color;
      }
    }
  }
  @media (max-width: $phone_max) {
    height: 50px;
  }
}
