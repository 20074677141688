/* Sizes */

/* Sizes */

/* Sizes */

/* Sizes */

.tooltip-ico {
  background: #ff0000;
  display: inline-flex;
  border-radius: 50%;
  color: #ffffff;
  position: relative;
  width: 11px;
  height: 11px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.tooltip-ico:before {
  content: "?";
  position: static;
  font-size: 9px;
  display: block;
  width: initial;
  height: initial;
  padding-left: 1px;
}

.tooltip-ico_info {
  color: #000000;
  background: #c8c8c8;
  width: 11px;
  height: 11px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.tooltip-ico_info:before {
  color: #000000;
  content: "!";
  position: static;
  font-size: 9px;
  display: block;
  width: initial;
  height: initial;
  padding-left: 1px;
  transform: rotate(180deg);
  background: none;
}

.tooltipster-sidetip .tooltipster-box {
  background: #ffffff;
  border: 2px solid #f3f2ef;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border {
  border-top-color: #f3f2ef;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: #f3f2ef;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
  border-top-color: #f3f2ef;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
  border-bottom-color: #f3f2ef;
}

.tooltip_templates {
  display: none;
}

.tooltipster-sidetip .tooltipster-box {
  border-radius: 20px;
}

.tooltipster-sidetip .tooltipster-content {
  color: #b8b6ae;
  font-weight: 400;
  text-align: center;
}

.tooltipster-sidetip .tooltipster-content .txt-grey {
  color: #38373d;
}

.arrow-prev,
.arrow-next,
.arrow-prev-white,
.arrow-next-white {
  width: 14px;
  height: 27px;
  opacity: 1;
  padding: 0;
  cursor: pointer;
}

.arrow-next {
  background: url("../../images/icons/nav_arr.svg");
}

.arrow-prev {
  background: url("../../images/icons/nav_arr.svg");
  transform: rotate(180deg);
}

.arrow-next-white {
  background: url("../../images/icons/nav_arr_white.svg");
}

.arrow-prev-white {
  background: url("../../images/icons/nav_arr_white.svg");
  transform: rotate(180deg);
}

.swiper-button-disabled {
  opacity: 0.2 !important;
}

/* Sizes */

/* Sizes */

.weekly-selections {
  padding: 0;
  text-align: start;
}

.weekly-selections__blocks-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 0 0;
  overflow: hidden;
  gap: 20px;
}

.weekly-selections__blocks-wrap .info-block-half {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 670px;
}

.weekly-selections .info-block-half__txt {
  margin-top: 13px;
}

.weekly-selections .info-block-half__txt p {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
}

.weekly-selections .info-block-half__desc {
  padding-right: 0;
  text-align: start;
}

.info-block-half,
.info-block-fw {
  position: relative;
  display: inline-flex;
  min-height: 600px;
}

.info-block-half:before,
.info-block-fw:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(180deg, rgba(73, 73, 73, 0.32) 0%, rgba(14, 14, 14, 0.54) 100%);
}

.info-block-half__image,
.info-block-fw__image {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
}

.info-block-half__image picture,
.info-block-fw__image picture {
  width: 100%;
  height: 100%;
}

.info-block-half__image img,
.info-block-fw__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info-block-half__content,
.info-block-fw__content {
  position: relative;
  padding: 60px 60px 60px 60px;
  color: #ffffff;
  display: flex;
  width: 100%;
  align-items: flex-end;
  z-index: 1;
}

.info-block-half__desc,
.info-block-fw__desc {
  max-width: 478px;
  margin-top: auto;
}

.info-block-half__txt,
.info-block-fw__txt {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  margin-top: 25px;
}

.info-block-half__link,
.info-block-fw__link {
  margin-left: auto;
  z-index: 2;
}

.info-block-fw {
  display: flex;
}

.info-block-fw__desc {
  max-width: 785px;
  padding-right: 20px;
}

.info-block-fw .btn {
  margin-left: auto;
  z-index: 2;
  flex: 0 0 auto;
  padding: 0;
  width: initial;
}

.info-block-type-1 {
  max-width: 325px;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
}

.info-block-type-1__image {
  display: flex;
}

.info-block-type-1__image img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.info-block-type-1__content {
  margin-top: 20px;
}

/* Sizes */

/* Sizes */

.blog-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 40px;
}

.blog-block {
  overflow: hidden;
}

.blog-block__wrapper {
  display: flex;
  gap: 20px;
}

.blog-block__wrapper a:hover {
  color: unset;
}

.blog-block_with-top-padding {
  padding-top: 60px;
}

.blog-block__title {
  margin-bottom: 30px;
  text-align: center;
}

.blog-block .blog-item {
  flex: calc((100% - 40px) / 3);
}

.blog-block__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 25px;
  padding-right: 43px;
}

.blog-block__nav-arrows {
  display: flex;
  justify-content: space-between;
  height: 27px;
  width: 47px;
}

.blog-block__nav .slider-pagination {
  display: flex;
  width: calc(100% - 77px);
  height: 1px;
  z-index: 10;
  background-color: #c8c8c8;
}

.blog-block__nav .slider-pagination .swiper-pagination-bullet {
  flex: 1 1 auto;
  height: 1px;
  background: #212121;
  margin: 0;
  border-radius: 0;
}

.blog-block__nav .swiper-pagination-lock {
  display: none;
}

.blog-block__nav .pagination {
  display: flex;
  width: 88%;
  height: 1px;
  z-index: 10;
  background-color: #c8c8c8;
}

.blog-block__nav .pagination .swiper-pagination-bullet {
  flex: 1 1 auto;
  height: 1px;
  margin: 0;
}

.blog-block__nav .pagination .swiper-pagination-bullet-active {
  background: #212121;
}

.blog-block__nav .arrow-left,
.blog-block__nav .arrow-right {
  width: 15px;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  background: url("../../images/icons/arrow_right.svg") no-repeat center;
  background-size: cover;
}

.blog-block__nav .arrow-left {
  transform: rotate(180deg);
}

.blog-block__nav.hidden {
  display: none;
}

.blog-item {
  display: flex;
  flex-direction: column;
  max-width: 440px;
}

.blog-item__img {
  margin-bottom: 20px;
  max-height: 360px;
}

.blog-item__img img {
  max-width: 100%;
  max-height: 100%;
  filter: gray;
  filter: grayscale(100%);
  transition: filter 0.3s linear;
}

.blog-item__img:hover img {
  filter: none;
}

.blog-item__title {
  margin-bottom: 10px;
}

.blog-item__content {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 10px;
}

.blog-item .btn {
  align-self: flex-start;
}

/* Sizes */

/* Sizes */

.cookie-policy {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  animation: slide-up 0.5s ease-out 1s forwards;
  background-color: #dadada;
}

.cookie-policy__wrapper {
  display: flex;
  gap: 24px;
  padding: 40px 40px 34px;
}

.cookie-policy__content h5 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #212121;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  margin-bottom: 10px;
}

.cookie-policy__content p {
  color: #212121;
  font-weight: 400;
}

.cookie-policy__btns {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding-top: 8px;
}

.cookie-policy__btns .btn {
  margin: 0;
  height: 60px;
}

.cookie-policy__btns .deny {
  width: 105px;
}

.cookie-policy__btns .allow {
  width: 148px;
}

.cookie-policy.visible {
  opacity: 1;
  visibility: visible;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

/* Sizes */

/* Sizes */

.subscribe-block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 40px 158px 40px 224px;
  background: #212121;
  color: #ffffff;
  transition: height 0.3s linear;
  min-height: 194px;
}

.subscribe-block__form {
  display: flex;
  align-items: center;
  gap: 8px;
}

.subscribe-block__form-input {
  position: relative;
}

.subscribe-block__form-input input {
  background-color: transparent;
  border: 1px solid #dadada;
  border-radius: 0;
  padding: 22px 18px 22px 30px;
  width: 325px;
  max-height: 60px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.subscribe-block__form-input label {
  position: absolute;
  top: 0;
  left: 20px;
  transform: translateY(-50%);
  background-color: #212121;
  color: #aaaaaa;
  padding: 0 10px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
}

.subscribe-block__txt {
  text-align: center;
}

.subscribe-block__txt span {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  margin-bottom: 10px;
}

.subscribe-block__txt p {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
}

:root {
  --tooltip-opacity: 1;
}

.tooltipster-show {
  opacity: var(--tooltip-opacity) !important;
}

.aroma-swiper-container {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}

.look-at-me {
  aspect-ratio: 2.34 / 1;
  overflow: hidden;
}

.look-at-me-item img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.look-at-me .slider-pagination {
  display: flex;
  position: absolute;
  left: 57px;
  bottom: 46px;
  width: calc(100% - 202px);
  height: 1px;
  background-color: #aaaaaa;
  z-index: 1;
}

.look-at-me .slider-pagination .swiper-pagination-bullet {
  flex: 1 1 auto;
  height: 1px;
  background: #ffffff;
  margin: 0;
  border-radius: 0;
}

.look-at-me .swiper-pagination-lock {
  display: none;
}

.look-at-me .arrow-prev-white,
.look-at-me .arrow-next-white {
  position: absolute;
  z-index: 1;
  bottom: 33px;
}

.look-at-me .arrow-next-white {
  right: 64px;
}

.look-at-me .arrow-prev-white {
  right: 97px;
}

.look-at-me .swiper-button-disabled {
  opacity: 0.5 !important;
}

.main-items-holder {
  margin: 80px 0;
  padding: 0;
  text-align: center;
}

.main-items-holder__title {
  margin-bottom: 40px;
}

.main-items-holder__products {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 20px;
  max-width: 1360px;
  margin: 0 0 40px;
}

.main-items-holder__products:hover {
  overflow-x: visible;
}

.editor-choise {
  position: relative;
  display: flex;
  padding-bottom: 47px;
  margin-bottom: 48px;
  color: #38373d;
}

.editor-choise__swiper {
  width: 100%;
}

.editor-choise__main {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 49px 77px 49px 0;
  width: 268px;
}

.editor-choise__main::before {
  display: none;
}

.editor-choise__main h2 {
  color: black;
}

.editor-choise__main .btn {
  margin: 0 auto;
}

.editor-choise__products {
  display: flex;
}

.editor-choise__img {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin: 30px auto;
}

.editor-choise__name {
  text-align: center;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  max-width: 192px;
  margin: 0 auto;
  margin-bottom: 2px;
}

.editor-choise__proff {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 40px;
}

.editor-choise__swiper {
  position: static !important;
}

.editor-choise__nav {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 27px;
  width: 100%;
}

.editor-choise__nav-arrows {
  display: flex;
  justify-content: space-between;
  height: 27px;
  width: 47px;
}

.editor-choise__nav .pagination {
  display: flex;
  width: calc(100% - 58px);
  height: 1px !important;
  background-color: #c8c8c8;
}

.editor-choise__nav .pagination .swiper-pagination-bullet {
  height: 1px;
  flex: 1 1 auto;
  background: #212121;
  margin: 0 !important;
}

.editor-choise__nav .swiper-pagination-lock {
  display: none;
}

.editor-choise__nav .arrow-left,
.editor-choise__nav .arrow-right {
  width: 14px;
  height: 100%;
  opacity: 1;
  cursor: pointer;
  background: url("../../images/icons/arrow_right.svg") no-repeat center;
  background-size: cover;
}

.editor-choise__nav .arrow-left {
  transform: rotate(180deg);
}

.editor-choise .btn-bottom {
  display: none;
}

.exclusive-bg {
  position: relative;
}

.exclusive-bg__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 63% 37%;
  overflow: hidden;
}

.exclusive-bg__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.exclusive-of-the-week {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding: 86px 0;
  margin: 0 -10px;
}

.exclusive-of-the-week__desc {
  text-align: center;
  padding-left: 58px;
}

.exclusive-of-the-week__desc h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.exclusive-of-the-week__desc h1 {
  color: #ffffff;
  margin-bottom: 30px;
}

.exclusive-of-the-week__desc > * {
  max-width: 497px;
}

.exclusive-of-the-week__desc,
.exclusive-of-the-week__image {
  flex: 1 1 calc(50% - 20px);
  margin: 0 10px;
}

.exclusive-of-the-week__image img {
  width: 100%;
  height: 100%;
  max-width: 440px;
  max-height: 554px;
  object-fit: cover;
}

.exclusive-of-the-week__txt {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
}

.exclusive-of-the-week__btns {
  display: flex;
  flex-direction: column;
  margin-top: 27px;
  align-items: center;
}

.exclusive-of-the-week__btns a:first-child {
  margin-bottom: 33px;
}

.brand-new {
  margin-top: 60px;
  text-align: center;
}

.brand-new__columns {
  display: flex;
  flex-wrap: wrap;
  margin: 30px -10px 0 -10px;
}

.brand-new__big-img {
  position: relative;
  overflow: hidden;
}

.brand-new__big-img,
.brand-new__right {
  margin: 0 10px;
  flex: 1 1 calc(50% - 20px);
}

.brand-new__bg-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.brand-new__brand-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.brand-new__products {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

.blog-block {
  margin: 60px 0;
}

.weekly-selections {
  margin: 60px 0 20px;
}

.aroma-container-block {
  max-width: 1440px;
  padding: 0 40px;
  margin: 0 auto;
}

.look-at-me-2 .info-block-fw__desc {
  margin-top: 0;
  padding-right: 3px;
}

.look-at-me-2 .info-block-fw__desc h2 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 64px;
  line-height: 60px;
  color: #212121;
  color: #ffffff;
}

.look-at-me-2 .info-block-fw__txt a p {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  margin-top: 17px;
  color: #ffffff;
}

.look-at-me-2 .btn_goto-svg-arrow span {
  margin-left: 20px;
}

.subscribe-block {
  margin-top: 80px;
  margin-bottom: 80px;
}

@media (min-width: 768px) {
  .exclusive-of-the-week__desc h1 {
    line-height: 66px;
  }

  .exclusive-of-the-week__btns {
    margin-top: 60px;
  }

  .exclusive-of-the-week__btns a:first-child {
    margin-bottom: 35px;
  }

  .brand-new {
    padding: 0 31px;
  }

  .brand-new__big-img {
    flex: none;
    height: 596px;
  }

  .brand-new .link {
    display: block;
    width: fit-content;
  }
}

@media (min-width: 1201px) {
  .weekly-selections__blocks-wrap {
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
  }

  .weekly-selections h3 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 36px;
  }

  .exclusive-of-the-week__desc h1 {
    padding-bottom: 7px;
  }

  .brand-new {
    text-align: start;
    padding: 0;
  }

  .brand-new__columns {
    margin-top: 40px;
  }

  .brand-new__big-img {
    flex: 1 1 calc(50% - 20px);
  }

  .brand-new__products {
    margin-bottom: 20px;
  }

  .brand-new .link {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.02em;
    width: fit-content;
  }

  .brand-new .link::before {
    bottom: 0;
  }

  .look-at-me-2 .info-block-fw__content {
    padding: 276px 52px 80px 58px;
  }
}

@media all and (max-width: 1439px) {
  .info-block-half__content {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 1439px) {
  .info-block-half__link {
    margin-left: 0;
    margin-top: 10px;
  }
}

@media (max-width: 1200px) {
  .weekly-selections__blocks-wrap .info-block-half {
    width: 100%;
  }

  .weekly-selections__blocks-wrap .info-block-half__content {
    padding: 0 60px 60px;
  }

  .weekly-selections__blocks-wrap .info-block-half__txt {
    margin-top: 25px;
  }

  .info-block-half,
  .info-block-fw {
    min-height: 600px;
  }

  .blog-container {
    padding: 0 0 0 20px;
  }

  .blog-block {
    padding: 0 28px;
  }

  .blog-block_with-top-padding {
    padding-top: 60px;
  }

  .blog-block .blog-item {
    flex: 0 0 100%;
  }

  .blog-block .blog-item:last-of-type {
    margin-right: 20px;
  }

  .cookie-policy__wrapper {
    flex-direction: column;
    gap: 0;
    padding: 40px;
  }

  .cookie-policy__content {
    margin-bottom: 22px;
  }

  .cookie-policy__btns {
    padding-top: 0;
  }

  .subscribe-block {
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    gap: 41px;
  }

  .aroma-swiper-container {
    padding: 0 21px;
  }

  .look-at-me .slider-pagination {
    bottom: 43px;
    left: 60px;
    margin-bottom: 0;
  }

  .look-at-me .arrow-prev-white,
  .look-at-me .arrow-next-white {
    bottom: 30px;
  }

  .main-items-holder {
    padding: 0 34px;
    margin: 60px 0 48px;
  }

  .main-items-holder__title {
    margin-bottom: 30px;
  }

  .main-items-holder__products {
    flex-wrap: wrap;
    gap: 20px 10px;
    margin: 0 auto 30px;
  }

  .editor-choise {
    padding-left: 31px;
  }

  .editor-choise__main {
    margin: 49px 20px 49px 0;
  }

  .editor-choise__nav {
    left: 31px;
    width: 667px;
  }

  .exclusive-bg__wrapper {
    grid-template-columns: 1fr;
  }

  .exclusive-bg__image.secondary {
    display: none;
  }

  .exclusive-of-the-week {
    flex-direction: column-reverse;
    padding: 80px 0 99px;
  }

  .exclusive-of-the-week__desc {
    padding-left: 0;
  }

  .exclusive-of-the-week__desc > * {
    max-width: 607px;
  }

  .exclusive-of-the-week__image {
    width: 100%;
    max-height: 613px;
    padding: 0 154px;
  }

  .exclusive-of-the-week__image img {
    max-width: initial;
    margin-bottom: 58px;
  }

  .brand-new__big-img {
    margin-bottom: 20px;
  }

  .blog-block {
    padding: 0 31px;
  }

  .blog-block__nav {
    padding-right: 21px;
  }

  .weekly-selections {
    padding: 0 31px;
    text-align: center;
  }

  .weekly-selections__blocks-wrap .info-block-half__content {
    padding: 0 58px 60px;
  }

  .weekly-selections__blocks-wrap .info-block-half__txt {
    margin-top: 13px;
  }

  .aroma-container-block {
    padding: 0 51px;
    max-width: 768px;
  }

  .look-at-me-2 .info-block-fw__desc {
    margin-top: 50px;
    padding-right: 3px;
  }

  .look-at-me-2 .btn_goto-svg-arrow {
    margin-top: 60px;
  }

  .subscribe-block {
    width: auto;
    margin: 0 31px;
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

@media all and (max-width: 1200px) {
  .info-block-fw__content {
    flex-direction: column;
    align-items: flex-start;
  }

  .info-block-fw .btn {
    margin-left: 0;
    margin-top: 30px;
  }

  .info-block-half {
    width: 100%;
  }

  .subscribe-block__txt {
    flex: 1 1 100%;
    width: 100%;
  }

  .brand-new__columns {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .weekly-selections__blocks-wrap .info-block-half__content {
    flex-direction: column;
    padding: 0 30px 30px 30px;
  }

  .weekly-selections__blocks-wrap .info-block-half__txt {
    margin-top: 35px;
  }

  .info-block-half,
  .info-block-fw {
    min-height: 410px;
    align-items: center;
  }

  .info-block-half__txt,
  .info-block-fw__txt {
    margin-top: 35px;
  }

  .info-block-half__link {
    margin-top: 20px;
  }

  .blog-container {
    padding: 0 20px;
  }

  .blog-block {
    padding: 0;
  }

  .blog-block__wrapper {
    gap: 0;
  }

  .blog-block_with-top-padding {
    margin-top: 60px;
  }

  .blog-block__title {
    margin-bottom: 15px;
  }

  .blog-block__nav {
    justify-content: center;
    padding-right: 0;
    margin-top: 15px;
  }

  .blog-block__nav-arrows {
    height: 20px;
    width: 40px;
  }

  .blog-block__nav .slider-pagination {
    display: none;
  }

  .blog-block__nav .arrow-left,
  .blog-block__nav .arrow-right {
    width: 10px;
  }

  .blog-block__nav .pagination {
    display: none;
  }

  .blog-item {
    max-width: 320px;
  }

  .blog-item:last-of-type {
    margin-right: 0;
  }

  .blog-item__img {
    height: 180px;
  }

  .blog-item__img img {
    width: 100%;
    object-fit: cover;
  }

  .blog-item .btn_open-svg-arrow {
    display: none;
  }

  .cookie-policy {
    width: 100%;
  }

  .cookie-policy__wrapper {
    padding: 20px;
  }

  .cookie-policy__content {
    margin-bottom: 4px;
  }

  .cookie-policy__content h5 {
    margin-bottom: 13px;
  }

  .subscribe-block__form {
    flex-direction: column;
    gap: 8px;
  }

  .subscribe-block__form-input input {
    padding: 22px 20px;
    width: 280px;
    max-height: 50px;
  }

  .subscribe-block__txt span {
    font-size: 22px;
    line-height: normal;
  }

  .aroma-swiper-container {
    padding: 0;
  }

  .look-at-me {
    aspect-ratio: 0.714 / 1;
  }

  .look-at-me .slider-pagination {
    bottom: 48px;
    left: 20px;
    width: calc(100% - 118px);
  }

  .look-at-me .arrow-prev-white,
  .look-at-me .arrow-next-white {
    bottom: 36px;
  }

  .look-at-me .arrow-next-white {
    right: 20px;
  }

  .look-at-me .arrow-prev-white {
    right: 54px;
  }

  .main-items-holder {
    padding: 0;
    margin: 60px 0;
  }

  .main-items-holder__products {
    justify-content: center;
    overflow-x: hidden;
    max-width: 320px;
    gap: 20px 20px;
  }

  .editor-choise {
    position: static;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 60px;
  }

  .editor-choise__main {
    width: 100%;
    margin: 0;
  }

  .editor-choise__main .btn {
    display: none;
  }

  .editor-choise__img {
    margin: 30px auto 5px;
    width: 32px;
    height: 32px;
  }

  .editor-choise__name {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.02em;
    margin-bottom: 0;
    max-width: fit-content;
  }

  .editor-choise__proff {
    font-family: "Cormorant", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    margin-bottom: 30px;
  }

  .editor-choise__products {
    width: 100%;
    margin-bottom: 20px;
  }

  .editor-choise__nav {
    position: static;
    width: fit-content;
    margin: 0 auto;
  }

  .editor-choise__nav .pagination {
    display: none;
  }

  .editor-choise__nav-arrows {
    height: 20px;
    width: 40px;
  }

  .editor-choise__nav .arrow-left,
  .editor-choise__nav .arrow-right {
    width: 10px;
  }

  .editor-choise .btn-bottom {
    display: flex;
    margin-top: 30px;
  }

  .exclusive-of-the-week {
    padding: 40px 0;
  }

  .exclusive-of-the-week__image {
    padding: 0 52px;
  }

  .exclusive-of-the-week__image img {
    max-width: initial;
    max-height: 294px;
    margin-bottom: 20px;
  }

  .exclusive-of-the-week__desc h3 {
    margin-bottom: 10px;
  }

  .exclusive-of-the-week__desc h1 {
    margin-bottom: 20px;
  }

  .exclusive-of-the-week__image {
    max-height: 314px;
  }

  .brand-new__big-img {
    height: 120px;
    flex: none;
  }

  .brand-new__bg-img {
    height: 120px;
  }

  .brand-new__brand-img {
    width: 111px;
    height: 38px;
  }

  .brand-new__products {
    gap: 10px;
  }

  .blog-block {
    width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  .blog-block__nav {
    margin-top: 10px;
  }

  .weekly-selections {
    margin: 60px 0;
    padding: 0;
  }

  .weekly-selections h2 {
    margin-bottom: 30px;
  }

  .weekly-selections .info-block-half__txt {
    margin-top: 20px;
  }

  .aroma-container-block {
    padding: 0;
  }

  .look-at-me-2 .info-block-fw__desc h2 {
    font-size: 40px;
    line-height: 45px;
  }

  .look-at-me-2 .info-block-fw__content {
    padding: 0 20px 40px;
  }

  .look-at-me-2 .info-block-fw__desc {
    margin-top: 132px;
  }

  .look-at-me-2 .info-block-fw__txt {
    margin-top: 6px;
  }

  .look-at-me-2 .btn_goto-svg-arrow {
    margin-top: 30px;
  }

  .subscribe-block {
    margin-left: -20px;
    margin-right: -20px;
  }
}

@media all and (max-width: 767px) {
  .info-block-half__content,
  .info-block-fw__content {
    padding: 30px 30px 30px 30px;
  }

  .info-block-fw__desc {
    padding-right: 0;
    text-align: center;
  }

  .info-block-fw__content {
    align-items: center;
  }

  .info-block-half__content {
    flex-direction: column;
    align-items: flex-start;
  }

  .subscribe-block__form-input label {
    left: 10px;
  }
}

@media (min-width: 1201px) and (max-width: 767px) {
  .weekly-selections h3 {
    font-size: 26px;
    line-height: 30px;
  }
}

@media (min-width: 529px) and (max-width: 767px) {
  .editor-choise__products {
    justify-content: center;
  }
}