@import "../uikit/vars/vars";
@import "../uikit/mixins";

.cookie-policy {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  animation: slide-up 0.5s ease-out 1s forwards;
  background-color: $color_grey_light;

  &__wrapper {
    display: flex;
    gap: 24px;
    padding: 40px 40px 34px;
  }

  &__content {
    h5 {
      @include custom-font-inter(700, 16px, 21px);
      color: $color_dark_grey_2;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      margin-bottom: 10px;
    }

    p {
      color: $color_dark_grey_2;
      font-weight: 400;
    }
  }

  &__btns {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding-top: 8px;

    .btn {
      margin: 0;
      height: 60px;
    }

    .deny {
      width: 105px;
    }
    .allow {
      width: 148px;
    }
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  @media (max-width: $tablet-max) {
    &__wrapper {
      flex-direction: column;
      gap: 0;
      padding: 40px;
    }
    &__content {
      margin-bottom: 22px;
    }
    &__btns {
      padding-top: 0;
    }
  }

  @media (max-width: $phone-max) {
    width: 100%;

    &__wrapper {
      padding: 20px;
    }

    &__content {
      margin-bottom: 4px;
      h5 {
        margin-bottom: 13px;
      }
    }
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
