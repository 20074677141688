@import "../uikit/vars/vars";
@import "../uikit/mixins";

.blog-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 40px;
  @media (max-width: $tablet_max) {
    padding: 0 0 0 20px;
  }
  @media (max-width: $phone_max) {
    padding: 0 20px;
  }
}

.blog-block {
  overflow: hidden;
  @media (max-width: $tablet_max) {
    padding: 0 28px;
  }
  @media (max-width: $phone_max) {
    padding: 0;
  }
  &__wrapper {
    display: flex;
    gap: 20px;
    @media (max-width: $phone_max) {
      gap: 0;
    }
    a:hover {
      color: unset;
    }
  }
  &_with-top-padding {
    padding-top: 60px;

    @media (max-width: $tablet-max) {
      padding-top: 60px;
    }

    @media (max-width: $phone-max) {
      margin-top: 60px;
    }
  }
  &__title {
    margin-bottom: 30px;
    text-align: center;
    @media (max-width: $phone_max) {
      margin-bottom: 15px;
    }
  }
  .blog-item {
    flex: calc((100% - 40px) / 3);
    @media (max-width: $tablet_max) {
      flex: 0 0 100%;
      &:last-of-type {
        margin-right: 20px;
      }
    }
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 25px;
    padding-right: 43px;
    @media (max-width: $phone_max) {
      justify-content: center;
      padding-right: 0;
      margin-top: 15px;

      &-arrows {
        height: 20px;
        width: 40px;
      }

      .slider-pagination {
        display: none;
      }
      .arrow-left,
      .arrow-right {
        width: 10px;
      }
    }
    &-arrows {
      display: flex;
      justify-content: space-between;
      height: 27px;
      width: 47px;
    }
    .slider-pagination {
      display: flex;
      width: calc(100% - 77px);
      height: 1px;
      z-index: 10;

      background-color: $color_dark_grey;

      .swiper-pagination-bullet {
        flex: 1 1 auto;
        height: 1px;
        background: $color_dark_grey_2;
        margin: 0;
        border-radius: 0;
      }
    }
    .swiper-pagination-lock {
      display: none;
    }
    .pagination {
      display: flex;
      width: 88%;
      height: 1px;
      z-index: 10;
      background-color: $color_dark_grey;
      @media (max-width: $phone_max) {
        display: none;
      }
      .swiper-pagination-bullet {
        flex: 1 1 auto;
        height: 1px;
        margin: 0;
        &-active {
          background: $color_dark_grey_2;
        }
      }
    }
    .arrow-left,
    .arrow-right {
      width: 15px;
      border: none;
      background-color: transparent;
      padding: 0;
      cursor: pointer;
      opacity: 1;
      background: url("../../images/icons/arrow_right.svg") no-repeat center;
      background-size: cover;
    }
    .arrow-left {
      transform: rotate(180deg);
    }
    &.hidden {
      display: none;
    }
  }
}

// Blog item

.blog-item {
  display: flex;
  flex-direction: column;
  max-width: 440px;

  @media (max-width: $phone_max) {
    max-width: 320px;

    &:last-of-type {
      margin-right: 0;
    }

    &__img {
      height: 180px;

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .btn_open-svg-arrow {
      display: none;
    }
  }
  &__img {
    margin-bottom: 20px;
    max-height: 360px;

    img {
      max-width: 100%;
      max-height: 100%;
      filter: gray;
      filter: grayscale(100%);
      transition: filter 0.3s linear;
    }

    &:hover {
      img {
        filter: none;
      }
    }
  }

  &__title {
    margin-bottom: 10px;
  }

  &__content {
    @include custom-font-inter(300, 16px, 23px);
    margin-bottom: 10px;
  }

  .btn {
    align-self: flex-start;
  }
}
